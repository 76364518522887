html {
    scroll-behavior: smooth;
}

@font-face {
    font-family: 'SFProDisplay';
    src: local('SFProDisplay-Semibold'),
        url('./fonts/SFProDisplay-Semibold.woff2') format('woff2'),
        url('./fonts/SFProDisplay-Semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
/*    font-display: swap;
*/}

@font-face {
    font-family: 'SFProText';
    src: local('SFProText-Semibold'), 
        url('./fonts/SFProText-Semibold.woff2') format('woff2'),
        url('./fonts/SFProText-Semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
/*    font-display: swap;
*/}

@font-face {
    font-family: 'SFProText';
    src: local('SFProText-Regular'),  
        url('./fonts/SFProText-Regular.woff2') format('woff2'),
        url('./fonts/SFProText-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
/*    font-display: swap;
*/}

@font-face {
    font-family: 'SFProDisplay';
    src: local('SFProDisplay-Bold'),   
        url('./fonts/SFProDisplay-Bold.woff2') format('woff2'),
        url('./fonts/SFProDisplay-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
/*    font-display: swap;
*/}

body {
    font-family: 'SFProText';
    font-weight: normal;
}

p {
    font-size: 16px;
    line-height: 24px;
}

a.btn-close {
    color: #3B3B3B;
    font-size: 22px;
    position: absolute;
    top: -6px;
}

h3 {
    font-family: 'SFProDisplay';
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    color: #3B3B3B;
    line-height: 28px;
}

h4 {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #181818;
}

.heading-top {
    font-family: 'SFProDisplay';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #181818;
    margin-bottom: 0;
}

.heading-sub {
    font-family: 'SFProText';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
}

.text-gray {
    color: #979797;
}

.text-gray-small {
    color: #d1d1d1;
    font-size: 14px;
}

a.btn.btn-mini {
    font-family: 'SFProText';
    background: #979797;
    color: #fff;
    font-size: 12px;
    font-weight: 600;
    padding: 2px 15px;
    border-radius: 50px;
}

p.media-txt {
    font-family: 'SFProText';
    font-style: normal;
    font-weight: normal;
}

.text-btn {
    display: flex;
    justify-content: space-between;
    padding: 28px 0px 32px 0px;
}

button.btn.btn-start {
    font-family: 'SFProDisplay';
    background: #000099;
    border: 4px solid rgb(190 190 245 / 99%);
    border-radius: 50px;
    color: #fff;
    width: 214px;
    font-size: 16px;
    padding: 12px 0px;
    display: block;
    margin: auto;
    font-weight: 600;
    margin-bottom: 20px;
}

button.btn-close {
    border: 0;
    background: none;
    padding: 0;
    margin: 0;
}

.text-orange {
    color: #FF9900;
}

.fs-16 {
    font-size: 16px;
}

.fs-12 {
    font-size: 12px !important;
}

.fs-13 {
    font-size: 13px;
}

.fs-14 {
    font-size: 14px;
}

.fw-600 {
    font-weight: 600;
}

.btn-txt {
    font-family: 'SFProText';
    padding-bottom: 1px;
    border-bottom: 1px solid;
    font-size: 12px;
    line-height: 24px;
    font-weight: normal;

}

.box-light-orange {
    background: rgba(255, 153, 0, 0.15);
    border-radius: 4px;
    padding: 10px 16px;
    display: flex;
    align-items: center;

}

a.btn.btn-orange {
    background: #FF9900;
    color: #fff;
    font-size: 12px;
    border-radius: 50px;
    padding: 8px 16px;
    font-weight: 600;
}

.footer {
    background: #000099;
}

.footer h6 {
    font-family: 'SFProDisplay';
}

.border-blue {
    border-color: rgba(255, 255, 255, 0.4) !important;
}

.social li a {
    background: #374A9A;
    border: 4px solid rgba(255, 255, 255, 0.4);
    width: 48px;
    height: 48px;
    display: block;
    line-height: 38px;
    text-align: center;
    border-radius: 50px;
}

.line {
    width: 134px;
    height: 5px;
    background: #fff;
    border-radius: 20px;
    display: block;
    margin: auto;
}

/*============================Alart css=============================*/
.notification--reminder {
    padding: 0px 20px 35px 20px;
    border-radius: 10px 10px 0px 0px;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 1000;
    background: #fff;
    overflow-y: scroll;
    height: 100%;
    top: 30px;
    transform: translateY(80px);
    animation: notification--in linear 500ms;
    animation-iteration-count: 1;
    transform-origin: 50% 50%;
    animation-fill-mode: forwards;
    /*when the spec is finished*/
    -webkit-animation: notification--in linear 500ms;
    -webkit-animation-iteration-count: 1;
    -webkit-transform-origin: 50% 50%;
    -webkit-animation-fill-mode: forwards;
    /*Chrome 16+, Safari 4+*/
    -moz-animation: notification--in linear 500ms;
    -moz-animation-iteration-count: 1;
    -moz-transform-origin: 50% 50%;
    -moz-animation-fill-mode: forwards;
    /*FF 5+*/
    -o-animation: notification--in linear 500ms;
    -o-animation-iteration-count: 1;
    -o-transform-origin: 50% 50%;
    -o-animation-fill-mode: forwards;
    /*Not implemented yet*/
    -ms-animation: notification--in linear 500ms;
    -ms-animation-iteration-count: 1;
    -ms-transform-origin: 50% 50%;
    -ms-animation-fill-mode: forwards;
    /*IE 10+*/
}

@keyframes notification--in {
    0% {
        transform: translate(0px, 80px);
    }

    100% {
        transform: translate(0px, 0px);
    }
}

@-moz-keyframes notification--in {
    0% {
        -moz-transform: translate(0px, 80px);
    }

    100% {
        -moz-transform: translate(0px, 0px);
    }
}

@-webkit-keyframes notification--in {
    0% {
        -webkit-transform: translate(0px, 80px);
    }

    100% {
        -webkit-transform: translate(0px, 0px);
    }
}

@-o-keyframes notification--in {
    0% {
        -o-transform: translate(0px, 80px);
    }

    100% {
        -o-transform: translate(0px, 0px);
    }
}

@-ms-keyframes notification--in {
    0% {
        -ms-transform: translate(0px, 80px);
    }

    100% {
        -ms-transform: translate(0px, 0px);
    }
}

.move--down {}

.notification--wrapper {
    position: fixed;
    height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 999;
    top: 0;
}

.notifi-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
}

.main-title {
    font-family: 'SFProDisplay';
    font-weight: bold;
    font-size: 28px;
    color: #3B3B3B;
    line-height: 40px;
    margin-bottom: 15px;
}

button.btn.btn-start-outline {
    background: #fff;
    border: 4px solid rgb(190 190 245 / 99%);
    border-radius: 50px;
    color: #000099;
    width: 214px;
    font-size: 16px;
    padding: 12px 0px;
    display: block;
    margin: auto;
    font-weight: 600;
    margin-bottom: 20px;
}

img.check {
    padding: 78px 0px 91px 0px;
}

.box-outline-dark {
    border: 1px solid #EEEEEE;
    border-radius: 8px;
}

.box-gray {
    background: #F9F9F9;
    border-radius: 8px;
    padding: 19px 15px 19px 15px !important;
}

.box-gray p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;

    color: #181818;
}

.text-dark-blue {
    color: #000099;
}

.mb-120 {
    margin-bottom: 120px !important;
}

.badge-mini-blue {
    background: #000099;
    border-radius: 50px;
    color: #ffffff;
    font-size: 12px;
    margin-bottom: 30px;
    width: 188px;
    height: 24px;
}

span.icon-outline-white {
    border: 2px solid #fff;
    width: 16px;
    height: 16px;
    display: inline-block;
    border-radius: 50px;
    line-height: 11px;
    vertical-align: baseline;
}

.text-green {
    color: #33B413 !important;
}

.text-red {
    color: #C82838 !important;
}

.mt-35 {
    margin-top: 35px;
}

.map-car {
    margin: 65px 0px 85px 0px;
}

.modal-bottom .modal-dialog .modal-content {
    border-radius: 0.3rem 0.3rem 0 0;
}

.modal-p-bottom .modal-dialog {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    max-width: 100% !important;
    margin: 0;
    transform: translateY(100%) !important;
    z-index: 1001;
    margin: 0px 16px 30px 16px;
}

.modal-p-bottom .modal-dialog .modal-content {
    border-radius: 12px;
    background: #f8f8f8;
}

.modal-p-bottom.show {
    overflow: hidden;
}

.modal-p-bottom.show .modal-dialog {
    transform: translateY(0%) !important;
    min-height: 80px;
}

.map-details {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    background: #fff;
    position: relative;
}

.code-number {
    margin: 30px 0px 160px 0px;
    height: 72px;
    padding: 20px 0px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;

}

.code-number p {
    font-family: 'SFProDisplay';
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: #3B3B3B;
    line-height: 32px !important;

}

.check-box {
    padding: 0px 27px;
}

.car-pump {
    margin: 30px 0px 50px 0px;
}

.box-shadow {
    background: #FFFFFF;
    box-shadow: 0px 4px 15px rgba(59, 59, 59, 0.12);
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 45px;
}

.span_pseudo,
.chiller_cb span:before,
.chiller_cb span:after {
    content: "";
    display: inline-block;
    background: #fff;
    width: 0;
    height: 0.2rem;
    position: absolute;
    transform-origin: 0% 0%;
}

.chiller_cb {
    position: relative;

    display: flex;

}

.chiller_cb input {
    display: none;
}

.chiller_cb input:checked~span {
    background: #FF9900;
    border-color: #FF9900;
}

.chiller_cb input:checked~span:before {
    width: 1rem;
    height: 0.15rem;
    transition: width 0.1s;
    transition-delay: 0.3s;
}

.chiller_cb input:checked~span:after {
    width: 0.4rem;
    height: 0.15rem;
    transition: width 0.1s;
    transition-delay: 0.2s;
}

.chiller_cb input:disabled~span {
    background: #ececec;
    border-color: #dcdcdc;
}

.chiller_cb input:disabled~label {
    color: #dcdcdc;
}

.chiller_cb input:disabled~label:hover {
    cursor: default;
}

.chiller_cb label {
    padding-left: 40px;
    position: relative;
    z-index: 2;
    cursor: pointer;
    margin-bottom: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #1B1B1B;
}

.chiller_cb span {
    display: inline-block;
    width: 1.2rem;
    height: 1.2rem;
    border: 2px solid #CCCCCC;
    ;
    position: absolute;
    border-radius: 4px;
    left: 0;
    transition: all 0.2s;
    z-index: 1;
    box-sizing: content-box;
}

.chiller_cb span:before {
    transform: rotate(-55deg);
    top: 1rem;
    left: 0.37rem;
}

.chiller_cb span:after {
    transform: rotate(35deg);
    bottom: 0.35rem;
    left: 0.2rem;
}

button.btn.btn-start.disabled {
    background: #CCCCEB !important;
    border-color: #CCCCEB;
}

.mb-275 {
    margin-bottom: 275px;
}

.ladem-header {
    display: flex;
    justify-content: space-between;

}

a.icon-mesg {
    background: #73A8E4;
    border: 2px solid rgba(115, 168, 228, 0.3);
    width: 25px;
    height: 25px;
    line-height: 20px;
    text-align: center;
    border-radius: 50px;
}

.fw-700 {
    font-weight: 700;
}

.media p {
    line-height: 16px;
}

.w-240 {
    width: 240px !important;
}

.box-gray p {
    line-height: 22px;
}

.bg-orange-light {
    background: #F2994A !important;
}

.cable h6 {
    font-family: 'SFProDisplay';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #181818;
}

.cable p {
    font-size: 14px;
    line-height: 20px;
    color: #979797;
    margin-bottom: 0px;
}

.cable {
    margin-bottom: 30px;
}

.cable .fa-angle-right {
    font-size: 22px;
}

.map-details .fa-angle-up {
    font-size: 22px;
}

.left-img {
    margin-right: 10px;
    margin-left: -4px;
}

/*
.left-img.px-0 {
    margin-left: -8px;
}*/

.mb-50 {
    margin-bottom: 50px !important;
}

.mb-235 {
    margin-bottom: 235px;
}

.mb-185 {
    margin-bottom: 185px;
}

.mb-400 {
    margin-bottom: 400px;
}

.footer p,
.box-light-orange p {
    line-height: 16px;
}

img.check-2 {
    padding: 105px 0px 110px 0px;
}

.my-48 {
    margin: 48px 0px;
}

.sie-img {
    margin: 45px 0px 55px 0px;
}

.form-control {
    height: 48px;
    border: 1px solid #CCCCCC;
    box-sizing: border-box;
    border-radius: 4px;
    font-size: 16px;
    line-height: 24px;
    color: #3B3B3B;
}

.form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #CCCCCC;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 0%);
}

label.label-top {
    color: #979797;
    font-family: 'SFProText';
    font-size: 12px;
    font-weight: 400;
    background: #fff;
    display: inline-block;
    position: absolute;
    z-index: 1;
    padding: 8px;
    margin-left: 8px;
    margin-top: -17px;
}

/*=======================changes===========================*/
.box-outline-dark p {
    line-height: 16px;
}

.notification--reminder.px-0 {
    padding: 0px !important;
}

.map-details {
    padding-left: 30px;
    padding-right: 30px;
}

.map-details p.text-gray.mb-1.fs-14 {
    line-height: 20px;
}

.my-40 {
    margin: 40px 0px !important;
}

p.text-gray.fs-14.ml-n1.my-4 {
    line-height: 20px;
}

a.text-gray.arrow-r {
    margin-top: 21px;
    display: block;
}

.lh-20 {
    line-height: 20px;
}

.mt-24 {
    margin-top: 24px;
}

.mid-txt {
    font-family: 'SFProDisplay';
}

a.text-orange.btn-txt.border-0 {
    font-size: 16px;
}

p.text-orange.mb-0.fs-12 {
    line-height: 16px;
}

.box-light-orange.my-48 {
    padding: 19px 15px;
}

.box-light-orange.mb-50 {
    padding: 19px 15px;
}

.e-auto .media {
    margin-bottom: 35px;
}

.e-auto p {
    font-family: 'SFProDisplay';
    line-height: 24px;
}

.e-auto span {
    line-height: 20px;
}

/*Responsive table*/
/*======
Media Queries
=======*/
/*========================*/
@media (min-width: 1200px) and (max-width: 2400px) {}

@media (min-width: 992px) and (max-width: 1199px) {}

@media (min-width: 768px) and (max-width: 991px) {}

@media (min-width: 320px) and (max-width: 767px) {}

@media (min-width: 320px) and (max-width: 480px) {}

.slide-enter {
    transform: translateX(100%);
}

.slide-enter-active {
    transform: translateX(0%);
    transition: transform 500ms ease-in-out;
}

.slide-exit {
    transform: translate(0%, -100%);
}

.slide-exit-active {
    transform: translate(-100%, -100%);
    transition: transform 500ms ease-in-out;
}

.slide-group {
    display: flex;
    flex-direction: row-reverse;
    height: 100%;
}

.timer-wrapper {
    display: flex;
    justify-content: center;
}

.time {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.loader {
    border: 3px solid #fff;
    border-top: 3px solid #FF9900;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
    position: fixed;
    top: 50%;
    left: 50%;
    margin-top: -25px;
    margin-left: -25px;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.map {
    height: 400px;
    width: 100%;
}

.dot {
    width: 6px;
    height: 6px;
    border-radius: 50% 50% 50% 50%;
    background: #000;
    position: absolute;
}

.pulse {
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    border: 2px solid #fff;
    background: #000;
    cursor: pointer;
    box-shadow: 0 0 0 rgba(105, 105, 105, 0.4);
    animation: pulse 1s infinite;
}

.pulse:hover {
    animation: none;
}

@-webkit-keyframes pulse {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(105, 105, 105, 0.4);
    }

    70% {
        -webkit-box-shadow: 0 0 0 20px rgba(105, 105, 105, 0);
    }

    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(105, 105, 105, 0);
    }
}

@keyframes pulse {
    0% {
        -moz-box-shadow: 0 0 0 0 rgba(105, 105, 105, 0.4);
        box-shadow: 0 0 0 0 rgba(105, 105, 105, 0.4);
    }

    70% {
        -moz-box-shadow: 0 0 0 20px rgba(105, 105, 105, 0);
        box-shadow: 0 0 0 20px rgba(105, 105, 105, 0);
    }

    100% {
        -moz-box-shadow: 0 0 0 0 rgba(105, 105, 105, 0);
        box-shadow: 0 0 0 0 rgba(105, 105, 105, 0);
    }
}

.pin {
    display: flex;
    width: 40px;
    height: 40px;
    border-radius: 50% 50% 50% 0;
    background: #33B413;
    position: absolute;
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    left: 50%;
    top: 50%;
    margin: -20px 0 0 -20px;
    align-items: center;
}

.pin:after {
    content: '';
    width: 28px;
    height: 28px;
    margin: 0px 0 0 6px;
    background: rgba(255, 255, 255, 0.81);
    opacity: 0.3;
    position: absolute;
    border-radius: 50%;
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.pin-availability {
    width: 50px;
    height: 17px;
    position: relative;
    left: -25px;
    top: -24px;
    text-align: center;
}

.pin-availability>div {
    font-family: 'SFProText';
    font-size: 12px;
    font-weight: 600;
    display: inline-block;
    vertical-align: middle;
    line-height: normal;
    color: #fff;
}

.pin-power {
    width: 50px;
    height: 17px;
    border-radius: 25px;
    background: #fff;
    position: relative;
    left: -24px;
    top: -42px;
    text-align: center;
}

.pin-power>div {
    font-family: 'SFProText';
    font-size: 10px;
    font-weight: 400;
    display: inline-block;
    vertical-align: middle;
    line-height: normal;
}

.modal-backdrop {
    display: none;
    z-index: 1040 !important;
}

.color-nav {
    background-color: #000099;
}

#wrap {
  position: relative;
  margin-top: 20px;
}

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-left: 30px;
  margin-right: 30px;
}

.main_center {
  margin: 30px 0;
}

.middle {
  flex-grow: 1;
  overflow: auto;
}

.middle-bottom {
  position: fixed;
  bottom: 0px;
  width: 100%;
  padding-right: 60px;
}

.gradient-top {
  background: #ffffff;
  background: -moz-linear-gradient(bottom, rgba(255,255,255,1), rgba(255,255,255,0));
  background: -webkit-linear-gradient(bottom, rgba(255,255,255,1), rgba(255,255,255,0));
  background: linear-gradient(to top, rgba(255,255,255,1), rgba(255,255,255,0));
}

.gradient-bottom {
  background: #ffffff;
  background: -moz-linear-gradient(top, rgba(255,255,255,1), rgba(255,255,255,0));
  background: -webkit-linear-gradient(top, rgba(255,255,255,1), rgba(255,255,255,0));
  background: linear-gradient(to bottom, rgba(255,255,255,1), rgba(255,255,255,0));
}
